import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
    grid: {
        height: "100%"
    },
    progress: {
        margin: "auto",
    }
}))

export default function Loading(props) {
    const classes = useStyles()

    return (
        <Grid container className={classes.grid}>
            <Grid item className={classes.progress}>
                <CircularProgress />
            </Grid>
        </Grid>)
}
