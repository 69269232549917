import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red, green } from '@material-ui/core/colors';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Grid from '@material-ui/core/Grid';
import ProductDetails from '../Modal'
import DeleteIcon from '@material-ui/icons/Delete';
import Snackbar from '../Snackbar'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: "auto"
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  title: {
    maxWidth: "160px",
    overflow: 'hidden',
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  desc: {
    overflow: 'hidden',
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  priceDiv: {
    position: "relative",
    width: "100%",
  },
  price: {
    ...theme.typography.button,
    marginRight: "auto",
    textAlign: "right",
    paddingRight: theme.spacing(1),
    color: green[700], 
    fontSize: "1rem",
  }
}));

export default function ProductCard(props) {
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const [addedToCart, setAddedToCart] = useState(false)
  const [favourite, setFavourite] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { title, desc, image, price } = props
  const firstLetter = title ? title.charAt(0).toUpperCase() : "A"

  function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const handleProductAddToCart = () => {
    setDialogOpen(true)
  }

  const handleProductRemoveFromCart = () => {
    setAddedToCart(false)
  }

  const handleProductAdded = () => {
    setAddedToCart(true)
    setDialogOpen(false)
  }

  const handleProductClose = () => {
    setDialogOpen(false)
  }

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const toggleFavourite = () => {
    setFavourite(!favourite)
  }


  return (
    <>
      <Grid item xs={12} sm={6} md={4} lg={3} xl={1}>
        <Card className={classes.root}>
          <CardHeader
            avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {firstLetter}
              </Avatar>
            }
            action={
              addedToCart && (<IconButton
                aria-label="settings"
                onClick={handleProductRemoveFromCart}>
                <DeleteIcon />
              </IconButton>)
            }
            title={<Typography className={classes.title}>{title}</Typography>}
          />
          <CardActionArea
            onClick={handleProductAddToCart}
          >
            <CardMedia
              className={classes.media}
              image={image}
              title={title}
            />
            <CardContent>
              <Typography className={classes.desc} variant="body2" color="textSecondary" component="p">
                {desc}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions disableSpacing>
            <IconButton
              aria-label="Add product to favorites"
              color={favourite ? "secondary" : "inherit"}
              onClick={toggleFavourite}
            >
              <FavoriteIcon />
            </IconButton>
            <IconButton
              aria-label="Add product to cart"
              color={addedToCart ? "primary" : "inherit"}
              onClick={handleProductAddToCart}
            >
              <AddShoppingCartIcon />
            </IconButton>
            <div className={classes.priceDiv}>
              <h2 className={classes.price}>{price}$</h2>
            </div>
          </CardActions>
        </Card>
      </Grid>
      <ProductDetails
        open={dialogOpen}
        title={title}
        desc={desc}
        handleClickOpen={() => setAddedToCart(true)}
        handleCancel={handleProductClose}
        handleAdd={handleProductAdded}
      />

    </>
  );
}
