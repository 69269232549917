import { Button, makeStyles, Typography } from "@material-ui/core"
import { useHistory } from "react-router"


const useStyles = makeStyles((theme) => ({
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}))

export default function Confirmation(props) {

    const classes = useStyles()
    const history = useHistory()


    const handleHome = () => {
        history.push('/')
    };

    return (
        <>
            <Typography variant="h5" gutterBottom>
                Thank you for your order.
            </Typography>
            <Typography variant="subtitle1">
                Your order number is #2001539. We have emailed your order confirmation, and will
                send you an update when your order has shipped.
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={handleHome}
                className={classes.button}
            >
                Go back home
            </Button>
        </>
    )
}