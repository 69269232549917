import React, { useContext, useState, useEffect } from "react"
import SplashScreen from "../components/SplashScreen"
import { addTmpUser, fetchMe } from "../axios/api"
import axios from "../axios/axios"

const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null)
    const [loading, setLoading] = useState(true)

    function saveToken(data){
        console.log(data)
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`
    }

    function tmpUser() {
        setLoading(true)
        const resource = addTmpUser()
        resource.tmpUser.then(res => {
            // console.log({res});
        }).catch(err => {
            // console.log({err});
        }).finally(() => {
            setLoading(false)
        })
    }

    function signup(email, password) {
        setLoading(true)
    }

    function signin(login, password) {
        setLoading(true)
        return
    }

    function logout() {
        setLoading(true)
        return
    }

    function updateProfile(newInfo) {
        setLoading(true)
        return
    }

    useEffect(() => {
        const resource = fetchMe()
        resource.user.then(res => {
            if (res && res.response.status === 200) {
                saveToken(res.data)
                setCurrentUser(res.data)
                setLoading(false)
            }
        }).catch(err => {
            if (err.response && err.response.status === 401) {
                tmpUser()
            }
        })
        return
    }, [])

    const value = {
        currentUser,
        loading,
        tmpUser,
        signin,
        signup,
        logout,
        updateProfile,
    }

    return (
        <AuthContext.Provider value={value}>
            {loading ? <SplashScreen /> : <>{children}</>}
        </AuthContext.Provider>
    )
}