import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginBottom: theme.spacing(2),
  },
}));

export default function ScrollableTabsButtonAuto(props) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const history = useHistory()

  const { categories } = props ?? []

  const handleChange = (event, newValue) => {
    setValue(newValue)
    var path = "/home/"
    if(newValue>0) path = "/home/" + categories[newValue-1].id
    history.replace(path)
  };

  return (
    <Grid item xs={12} >
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="All" />
            {categories.map((category, index) => (
              <Tab label={category.nameFr} key={category.nameFr}/>
            ))}
          </Tabs>
        </AppBar>
      </div>
    </Grid>
  );
}
