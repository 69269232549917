import { Container } from '@mui/material'
import React, { useState } from 'react'
import { fetchUserInfo } from '../../axios/api'
import UserCard from '../../components/Card/UserCard'
import MyCopyToClipboard from '../../components/CopyToClipboard'
import EmptyDirectory from '../../components/Empty'
import CenteredGrid from '../../components/Grid'
import Search from "../../components/Search"
import { useProduct } from '../../utils/useProduct'

export default function Signup() {

    const { loading, user } = useProduct()
    const [searchResult, setSearchResult] = useState(null)

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            fetchUserInfo(e.target.value).user.then((r) => 
                setSearchResult(r.data.user)
            )
        }
    }

    return (
        <Container>
            <Search handleSearch={handleSearch} />
            <CenteredGrid>
                {searchResult &&
                    (<UserCard
                        crossorigin
                        title={searchResult.full_name}
                        username={searchResult.username}
                        image={searchResult.profile_pic_url} />
                    )}
                {(searchResult === null) &&
                    <EmptyDirectory />
                }
            </CenteredGrid>
            <MyCopyToClipboard text="324756" />
        </Container>
    )
}
