import { makeStyles } from "@material-ui/core";
import { Typography } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { useEffect, useState } from "react";
import Loading from "../Loading";

const useStyles = makeStyles((theme) => ({
    grid: {
        height: "100vh"
    },
    progress: {
        margin: "auto",
    },
    img: {
        height: '150px',
        animation: `$fadeIn 2000ms ${theme.transitions.easing.easeInOut}`,
    },
    appBarShift: {
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    },
}));


export default function SplashScreen() {
    const classes = useStyles()
    const [hidden, setHidden] = useState(true)

    useEffect(() => {
        setTimeout(()=>{
            setHidden(false)
        }, 2500)
      }, [])

    return (
        <Grid container className={classes.grid}>
            <Grid item className={classes.progress}>
                <Grid item>
                    <img src="/logo-red-v2.6276957f.png" alt="Empty Directory" className={classes.img} />
                </Grid>
                <Grid item xs={12} hidden={hidden}>
                    <Loading />
                </Grid>
            </Grid>
        </Grid>
    )
}