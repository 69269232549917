import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TvIcon from '@mui/icons-material/Tv';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

export default function PostTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Tabs value={value} onChange={handleChange} aria-label="icon label tabs example">
      <Tab icon={<AllInclusiveIcon />} iconPosition="start" label="ALL" />
      <Tab icon={<InsertPhotoIcon />} iconPosition="start" label="IMAGES" />
      <Tab icon={<VideoCameraBackIcon />} iconPosition="start" label="VIDEOS" />
      <Tab icon={<HistoryToggleOffIcon />} iconPosition="start" label="STORIES" />
      <Tab icon={<TvIcon />} iconPosition="start" label="IGTV" />
      <Tab icon={<LightbulbIcon />} iconPosition="end" label="HIGHLIGHTS" />
    </Tabs>
  );
}
