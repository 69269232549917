import React, { useReducer, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu'
import clsx from 'clsx'
import ItemsDrawer from '../Drawer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Badge from '@material-ui/core/Badge';
import { useHistory } from 'react-router-dom'


const drawerWidth = 240;
const drawerWidthClosed = 72;



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    dispaly: 'flex',
    overflow: "hidden"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButtonHidden: {
    display: 'none',
  },
  content: {
    display: 'flex',
    width: "100%",
  },
  contentShrinked: {
    width: `calc(100% - ${drawerWidthClosed}px)`
  },
  contentOpen: {
    width: `calc(100% - ${drawerWidth}px)`
  },
  appBarSpacer: theme.mixins.toolbar,
  scroll: {
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",
  }
}));


export default function Navbar(props) {
  const classes = useStyles();
  // const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [auth, setAuth] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = useState(false)
  const history = useHistory()

  //const matches = useMediaQuery('(max-width:1350px)');
  const phone = useMediaQuery('(max-width:800px)');


  const menuOpen = Boolean(anchorEl);

  var title = "POOP"

  const handleToolbalLink = (path) => {
    history.push(path)
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <div className={classes.root}>
      <AppBar position="absolute" className={clsx(classes.appBar, open && !phone && classes.appBarShift)}>
        <Toolbar>
          <IconButton
            edge="start"
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton
            aria-label="Cart"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => handleToolbalLink("/cart")}
          >
            <Badge color="secondary" variant="dot">
              <ShoppingCartIcon />
            </Badge>
          </IconButton>
          <IconButton
            aria-label="Saved products"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={() => handleToolbalLink("/favourite")}
          >
            <FavoriteIcon />
          </IconButton>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
              disabled={!auth}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={menuOpen}
              onClose={handleClose}
            >
              <MenuItem >Profile</MenuItem>
              <MenuItem disabled={loading}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <div className={clsx(classes.content)}>
        <ItemsDrawer
          open={open}
          setOpen={setOpen}
          handleDrawerClose={toggleDrawer}
        />
        <div className={classes.scroll}>
          {props.children}
        </div>
      </div>
    </div>
  );
}