import Navbar from '../../components/Navbar'
import Card from '../../components/Card'
import CenteredGrid from '../../components/Grid'
import TabPanel from '../../components/Tabs'
import Loading from '../../components/Loading'
import EmptyDirectory from '../../components/Empty'
import { useProduct } from '../../utils/useProduct'
import { useEffect } from 'react'

export default function Home(props) {
    const { categoryId } = props.match.params
    var { loading, categories, filteredProducts } = useProduct(categoryId)
    return (
        <>
            {loading ?
                <Loading />
                :
                <>
                    {categories && categories.length > 0 &&
                        (<TabPanel categories={categories} />)}

                    {filteredProducts && filteredProducts.length > 0 && (
                        <CenteredGrid>
                            {filteredProducts.map((product, index) => (
                                <Card
                                    key={index}
                                    desc={product.description}
                                    image={product.thumbnail}
                                    title={product.title}
                                    price={product.price} />
                            ))}
                        </CenteredGrid>

                    )}
                    {(filteredProducts.length === 0) && <EmptyDirectory />}
                </>
            }
        </>
    )
}