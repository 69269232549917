import axios from "./axios"
// import { useAuth } from "./Authprovider"
// import { GET_CATEGORIES_PATH, GET_PRODUCTS_PATH } from "./path"

// const { currentUser } = useAuth()


const get = async (path) => {
    try {
        const res = await axios.get(path)
        return res
    } catch (err) {
        throw err
    }
}

const add = async (path, payload) => {
    try {
        const res = await axios
            .patch(path)
        return res
    } catch (err) {
        throw err
    }
}

const remove = async (path, payload) => {
    try {
        const res = await axios
            .delete(path)
        return res
    } catch (err) {
        throw err
    }
}

// export const fetchHome = () => {
//     const productsPromise = fetch("/products")
//     const categoriesPromise = fetch("/categories")
//     return {
//         products: productsPromise,
//         categories: categoriesPromise
//     }
// }

export const fetchUserInfo = (username) => {
    try {
        const query = `https://us-central1-igistatest.cloudfunctions.net/get-user-info?username=${username}`
        const userInfoPromise = get(query)
        return {
            user: userInfoPromise
        }
    } catch (err) {
        throw err
    }
}

export const fetchProducts = () => {
    try {
        const productsPromise = get("/products")
        return {
            products: productsPromise
        }
    } catch (err) {
        throw err
    }
}
export const fetchProductsByCat = (cat) => {
    const query = cat ? `/categories/${cat}` : '/products'
    try {
        const filteredProductsPromise = get(query)
        return {
            filteredProducts: filteredProductsPromise
        }
    } catch (err) {
        throw err
    }
}

export const fetchCategories = () => {
    try {
        const categoriesPromise = get("/categories")
        return {
            categories: categoriesPromise
        }     
    } catch (err) {
        throw err
    }
}

export const fetchMe = () => {
    try {
        const userPromise = get("/auth/me")
        return {
            user: userPromise
        }
    } catch (err) {
        throw err
    }
}

export const addTmpUser = () => {
    try {
        const tmpUserPromise = get("/auth/user/tmp")
        return {
            tmpUser: tmpUserPromise
        }
    } catch (err) {
        throw err
    }
}

export const fetchFavourite = () => {
    try {
        const favouritesPromise = get("/products")
        return {
            favourites: favouritesPromise
        }
    } catch (err) {
        throw err
    }
}


export const addToFavourite = () => {
    try {
        const addToFavouritesPromise = add("/products")
        return {
            favourites: addToFavouritesPromise
        }
    } catch (err) {
        throw err
    }
}

export const removeFromFavourite = () => {
    try {
        const removeFromFavouritePromise = remove("/products")
        return {
            favourites: removeFromFavouritePromise
        }
    } catch (err) {
        throw err
    }
}
