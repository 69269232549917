import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import HistoryIcon from '@mui/icons-material/History';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';

export default function Search(props) {
  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', maxWidth: "700px", width: "100%", height: 64, margin: "50px auto", position: 'sticky', top: '60px' }}
    >
      <IconButton sx={{ p: '10px' }} aria-label="menu">
        <SearchIcon onClick={props.handleSearch}/>
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Start typing..."
        inputProps={{ 'aria-label': 'Start typing' }}
        onKeyDown={props.handleSearch}
      />
      <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
        <HistoryIcon />
      </IconButton>
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
        <FilterListIcon />
      </IconButton>
    </Paper>
  );
}
