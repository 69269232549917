import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import Typography from '@material-ui/core/Typography';
import InputSlider from '../Slider'

export default function ProductDetails(props) {

    const descriptionElementRef = React.useRef(null);

    React.useEffect(() => {
        if (props.open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [props.open]);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                scroll={props.scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>
                <DialogContent dividers>
                    <Typography>{props.desc}</Typography>
                </DialogContent>
                <DialogContent dividers>
                    <InputSlider />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={props.handleCancel}
                        color="primary"
                        size="small">
                        Cancel
                    </Button>
                    <Button
                        onClick={props.handleAdd}
                        color="primary"
                        size="small"
                        variant="contained"
                        startIcon={<AddShoppingCartIcon />}>
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
