import Card from '../../components/Card'
import CenteredGrid from '../../components/Grid'
import Loading from '../../components/Loading'
import EmptyDirectory from '../../components/Empty'
import { useProduct } from '../../utils/useProduct'

export default function Categories() {

    const { loading, categories } = useProduct()

    return (
        <>
            {loading ?
                <Loading />
                :
                <>
                    {categories && categories.length > 0 && (
                        <CenteredGrid>
                            {categories.map((category, index) => (
                                <Card
                                    key={index}
                                    desc={category.description}
                                    image={category.thumbnail}
                                    title={category.title}
                                    price={category.price} />
                            ))}
                        </CenteredGrid>
                    )}
                    {(categories.length === 0) && <EmptyDirectory />}
                </>
            }
        </>
    )
}