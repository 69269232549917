import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import Home from "./pages/home"
import Favourite from './pages/favourite'
import Checkout from "./pages/checkout/Checkout"
import Navbar from './components/Navbar'
import DisplayContainer from './components/Container'
import FAB from './components/FAB'
import History from "./pages/history"
import { AuthProvider } from "./utils/Authprovider"
import Categories from "./pages/categories"
import DemoPage from "./pages/search"
import Signup from "./pages/signup"

function AppRouter() {
  return (
    <Router>
      <AuthProvider>
        <Navbar>
          <DisplayContainer>
            <Switch>
              <Route path="/aboutus" component={Home} />
              <Route path="/favourite" component={Favourite} />
              <Route path="/categories" component={Categories} />
              <Route path="/checkout" component={Checkout} />
              <Route path='/history' component={History} />
              <Route exact path="/home/:categoryId" component={Home} />
              <Route path="/home" component={Home} />
              <Route path="/test" component={DemoPage} />
              <Route path="/signup" component={Signup} />
              <Redirect to="/home" />
            </Switch>
          </DisplayContainer>
        </Navbar>
        <FAB />
      </AuthProvider>
    </Router>
  )
}

export default AppRouter