import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { MainListItems, SecondaryListItems } from './listItems';
import { useHistory } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    toolbarIcon: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    drawerPaper: {
        position: 'relative',
        display: 'inline-flex',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        maxWidth: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    fa: {
        display: "inline-flex"
    }
}));



export default function ItemsDrawer(props) {
    const classes = useStyles()
    const history = useHistory()

    const { open, setOpen, handleDrawerClose } = props
    const matches = useMediaQuery('(max-width:800px)');

    const handleOpenLink = (path) => {
        history.push(path)
        if(matches) setOpen(false)
    }

    return (
        <Drawer
            variant={matches ? "temporary" : "permanent"}
            className={classes.fa}
            classes={{
                paper: clsx(classes.drawerPaper, !props.open && classes.drawerPaperClose),
            }}
            open={open}
        >
            <div className={classes.toolbarIcon}>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
            <Divider />
            <List>
                <MainListItems goto={handleOpenLink} />
            </List>
            <Divider />
            <List>
                <SecondaryListItems goto={handleOpenLink} />
            </List>
        </Drawer>
    );
}