import Card from '../../components/Card'
import CenteredGrid from '../../components/Grid'
import Loading from '../../components/Loading'
import EmptyDirectory from '../../components/Empty'
import { useProduct } from '../../utils/useProduct'

export default function Favourite() {

    const { loading, favouriteProducts } = useProduct()

    return (
        <>
            {loading ?
                <Loading />
                :
                <>
                    {favouriteProducts && favouriteProducts.length > 0 && (
                        <CenteredGrid>
                            {favouriteProducts.map((product, index) => (
                                <Card
                                    key={index}
                                    desc={product.description}
                                    image={product.thumbnail}
                                    title={product.title}
                                    price={product.price} />
                            ))}
                        </CenteredGrid>

                    )}
                    {(favouriteProducts.length === 0) && <EmptyDirectory />}
                </>
            }
        </>
    )
}