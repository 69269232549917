import AppRouter from './router'
import CssBaseline from '@material-ui/core/CssBaseline';



function App() {
  return (
    <>
          <CssBaseline />
          <AppRouter />  
    </>
  );
}

export default App;
