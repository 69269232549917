import React, { useState } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// @material-ui/core components
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from "@mui/material";
import TextField from '@mui/material/TextField';




const MyCopyToClipboard = (props) => {
    const [copied, setCopied] = useState(false);

    return (
        <div style={{ display: "flex", width: '100%', margin: "auto", alignItems: 'center', columnGap: '10px', margin: "50px 0" }}>
            <TextField id="outlined-basic" label="Verification code" value={props.text} variant="outlined" disabled />
            <CopyToClipboard text={props.text} onCopy={() => setCopied(true)}>
                <Button color={copied ? "success" : "primary"} variant="contained" endIcon={<ContentCopyIcon />}>Copy</Button>
            </CopyToClipboard>
        </div>
    );
};

export default MyCopyToClipboard;