import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LayersIcon from '@material-ui/icons/Layers';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CategoryIcon from '@material-ui/icons/Category';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';

export function MainListItems(props) {


  return (
    <div>
      <ListItem
        button
        onClick={() => props.goto("/home")}
      >
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem
        button
        onClick={() => props.goto("/categories")}>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Categories" />
      </ListItem>
      <ListItem
        button
        onClick={() => props.goto("/cart")}
      >
        <ListItemIcon>
          <ShoppingCartIcon />
        </ListItemIcon>
        <ListItemText primary="Cart" />
      </ListItem>
      <ListItem
        button
        onClick={() => props.goto("/favourite")}
      >
        <ListItemIcon>
          <FavoriteIcon />
        </ListItemIcon>
        <ListItemText primary="Favourite" />
      </ListItem>

      <ListItem
        button
        onClick={() => props.goto("/history")}
      >
        <ListItemIcon>
          <PlaylistAddCheckIcon />
        </ListItemIcon>
        <ListItemText primary="History" />
      </ListItem>
    </div>
  )
}

export function SecondaryListItems(props) {

  return (
    <div>
      <ListSubheader inset>More</ListSubheader>
      <ListItem
        button
        onClick={() => props.goto("/aboutus")}
      >
        <ListItemIcon>
          <LayersIcon />
        </ListItemIcon>
        <ListItemText primary="About us " />
        <ListItemIcon >
          <OpenInNewIcon style={{ fontSize: 15, marginLeft: "10px" }} />
        </ListItemIcon>
      </ListItem>
      <ListItem
        button
        onClick={() => props.goto("/checkout")}
      >
        <ListItemIcon>
          <PlaylistAddCheckIcon />
        </ListItemIcon>
        <ListItemText primary="Checkout test" />
      </ListItem>
    </div>
  )
}
