import axios from 'axios';

// axios.interceptors.response.use(response => response, error => {
//     const status = error.response ? error.response.status : null
  
//     if (status === 401) {
//       return refreshToken(store).then(_ => {
//         error.config.headers['Authorization'] = 'Bearer ' + store.state.auth.token;
//         return axios.request(error.config);
//       })
//       .catch(err => err);
//     }
//     return Promise.reject(error);
//   });

  
export default axios.create({
    baseURL: "https://sayertti-api.herokuapp.com/api",
    timeout: 10000
});