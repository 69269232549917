import { Grid } from '@mui/material'
import React from 'react'
import EmptyDirectory from '../../components/Empty'
import PostTabs from '../../components/PostTabs'
import Search from "../../components/Search"
import Paper from '@mui/material/Paper'



export default function DemoPage() {
 


  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Search/>
      </Grid>
      <Grid item xs={12}>
        <PostTabs />
      </Grid>
      <Grid item xs={12} height="auto">
        <Paper sx={{ p: '20px 0px'}} variant="outlined" style={{ backgroundColor: "none" }}>
          <EmptyDirectory />
        </Paper>
      </Grid>
    </Grid>
  )
}
