import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
}));

export default function OpenIconSpeedDial() {
  const classes = useStyles();
  const history = useHistory()

  const handleClick = () => {
    history.push('/cart')
  }
  return (
      <SpeedDial
        ariaLabel="My cart"
        className={classes.speedDial}
        icon={<ShoppingCartIcon/>}
        onClick={handleClick}
        open
      >
      </SpeedDial>
  );
}
