import HistoryAccordion from "../../components/Accordion";
import EmptyDirectory from "../../components/Empty";
import Loading from "../../components/Loading";
import { useProduct } from "../../utils/useProduct";


export default function History() {

    const { loading, history } = useProduct()

    return (
        <>
            {loading ?
                <Loading />
                :
                <>
                    {history && history.length > 0 &&
                        (<HistoryAccordion history={history} />)}
                    {(history.length === 0) && <EmptyDirectory />}
                </>
            }
        </>
    );
}
