import { useReducer, useEffect, useState } from "react"
import { fetchCategories, fetchProducts, fetchProductsByCat, fetchUserInfo } from "../axios/api"
// import { useAuth } from "./Authprovider"

const ACTIONS = {
    SELECT_CATEGORY: "select-category",
    SET_PRODUCTS: "set-products",
    SET_FILTERED_PRODUCTS: "set-filtered_products",
    SET_CATEGORIES: "set-categories",
    SET_ADDED_PRODUCTS: "set-added-products",
    SET_FAVOURITE_PRODUCTS: "set-favourite-products",
    SET_LOADING: "set-loading",
    SET_HISTORY: "set-history",
    SET_USERINFO: 'set-user-info',
}

export const ROOT_CATEGORY = { name: "All", id: null, products: [] }

function reducer(state, { type, payload }) {
    switch (type) {
        case ACTIONS.SET_USERINFO:
            return {
                ...state,
                user: payload.user,
            }
        case ACTIONS.SELECT_CATEGORY:
            return {
                categoryId: payload.categoryId,
                category: payload.category,
            }
        case ACTIONS.SET_PRODUCTS:
            return {
                ...state,
                products: payload.products,
            }
        case ACTIONS.SET_FILTERED_PRODUCTS:
            return {
                ...state,
                filteredProducts: payload.filteredProducts,
            }
        case ACTIONS.SET_CATEGORIES:
            return {
                ...state,
                categories: payload.categories,
            }
        case ACTIONS.SET_ADDED_PRODUCTS:
            return {
                ...state,
                addedProducts: payload.addedProducts,
            }
        case ACTIONS.SET_FAVOURITE_PRODUCTS:
            return {
                ...state,
                favouriteProducts: payload.favouriteProducts,
            }
        case ACTIONS.SET_HISTORY:
            return {
                ...state,
                hsitory: payload.history,
            }
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: payload.loading,
            }
        default:
            return state
    }
}

export function useProduct(categoryId) {
    const [loading, setLoading] = useState(true)

    const [state, dispatch] = useReducer(reducer, {
        loading: loading,
        categories: [],
        products: [],
        filteredProducts: [],
        favouriteProducts: [],
        addedProducts: [],
        history: [],
        user: null,
    })

    // const { currentUser } = useAuth()

    // useEffect(() => {
    //     dispatch({
    //         type: ACTIONS.SELECT_CATEGORY,
    //         payload: { categoryId, category }
    //     })
    // }, [categoryId, category])

    // useEffect(() => {
    //     fetchUserInfo.user.then(res => {
    //         return dispatch({
    //             type: ACTIONS.SET_USERINFO,
    //             payload: { user: res.data.user ?? null },
    //         })
    //     })
    // }, [])

    useEffect(() => {
        fetchProducts().products.then(res => {
            return dispatch({
                type: ACTIONS.SET_PRODUCTS,
                payload: { products: res.data.products ?? [] },
            })
        })

    }, [])

    useEffect(() => {
        fetchProductsByCat(categoryId).filteredProducts.then(res => {
            return dispatch({
                type: ACTIONS.SET_FILTERED_PRODUCTS,
                payload: { filteredProducts: res.data.products ?? [] },
            })
        }).catch(() => {
            setLoading(false)
        })
    }, [categoryId])


    useEffect(() => {
        fetchCategories().categories.then(res => {
            return dispatch({
                type: ACTIONS.SET_CATEGORIES,
                payload: { categories: res.data.categories ?? [] },
            })
        })
    }, [])

    useEffect(() => {
        // TODO: Needs review if products/categories are empty api side
        if (state.categories.length > 0 && state.products.length > 0 && state.filteredProducts.length > 0) setLoading(false)
        return dispatch({
            type: ACTIONS.SET_LOADING,
            payload: { loading: loading },
        })
    }, [state.categories, state.products, state.filteredProducts, loading])

    // TODO: Add favourite and cart to api for fetching

    // useEffect(() => {
    //     // TODO: Query cart
    //     return dispatch({
    //         type: ACTIONS.SET_ADDED_PRODUCTS,
    //         payload: { childFolders: snapshot.docs.map(database.formatDoc) },
    //     })
    // }, [currentUser])

    // useEffect(() => {
    //     // TODO: Query favourites
    //     return dispatch({
    //         type: ACTIONS.SET_FAVOURITE_PRODUCTS,
    //         payload: { childFiles: snapshot.docs.map(database.formatDoc) },
    //     })

    // }, [currentUser])

    // useEffect(() => {
    //     // TODO: Query history
    //     return dispatch({
    //         type: ACTIONS.SET_HISTORY,
    //         payload: { history: snapshot.docs.map(database.formatDoc) },
    //     })

    // }, [currentUser])

    return state
}