import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ItemsList from '../List';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  details: {
    width: "100%",
  }
}));

export default function HistoryAccordion(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  // const { orders } = props
  const orders = [
    {title: "test1", desc: "desc1"},
    {title: "test2", desc: "desc2"},
    {title: "test3", desc: "desc3"},
    {title: "test4", desc: "desc4"}
  ]

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {orders.map((order, idx) => (
        <Accordion expanded={expanded === `panel${idx}`} onChange={handleChange(`panel${idx}`)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${idx}bh-content`}
          id={`panel${idx}bh-header`}
        >
          <Typography className={classes.heading}>{order.title}</Typography>
          <Typography className={classes.secondaryHeading}>{order.desc}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <ItemsList />
        </AccordionDetails>
      </Accordion>
      ))}
    </div>
  );
}
