import { Button, Typography } from '@material-ui/core'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router';



const useStyles = makeStyles((theme) => ({
    grid: {
        height: "100%"
    },
    progress: {
        margin: "auto",
    },
    img: {
        height: '150px',
        transform: "translateX(15%)",
    },
    button: {
        marginTop: theme.spacing(3),
        alignSelf: "center",
    }, 
    btnGrid: {
        display: "flex",
        justifyContent: "center"
    }
}))


export default function EmptyDirectory() {
    const classes = useStyles();
    const history = useHistory()

    const handleHome = () => {
        history.replace('/')
    };

    return (
        <Grid container className={classes.grid}>
            <Grid item className={classes.progress}>
                <Grid item>
                    <img src="/assets/empty.png" alt="Empty Directory" className={classes.img} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">
                        Nothing to show here.
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.btnGrid}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleHome}
                        className={classes.button}
                    >
                        Go to home
                    </Button>
                </Grid>

            </Grid>
        </Grid>
    )
}